import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import themeReducer from '../feature/Theme/store';
import authReducer from '../feature/Auth/store';
import profileReducer from '../feature/Profile/store';
import adsReducer from '../feature/Ads/store';
import tasksReducer from '../feature/Tasks/store';
import usersReducer from '../feature/Users/store';
import logsReducer from '../feature/Logs/store';

export interface RootState {
    themeReducer: any;
    authReducer: any;
    profileReducer: any;
    adsReducer: any;
    tasksReducer: any;
    usersReducer: any;
    logsReducer: any;
}

const authReducerFilter = createBlacklistFilter('authReducer', ['errorMessage']);

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authReducer'],
    transforms: [authReducerFilter],
  };

const rootReducer = () =>
    combineReducers<RootState>({
        themeReducer: themeReducer,
        authReducer: authReducer,
        profileReducer: profileReducer,
        adsReducer: adsReducer,
        tasksReducer: tasksReducer,
        usersReducer: usersReducer,
        logsReducer: logsReducer
    });

const persistedReducer = persistReducer(persistConfig, rootReducer());

export function store() {
    return configureStore({
        reducer: persistedReducer,
        middleware: getDefaultMiddleware({
            serializableCheck: false,
        }),
    });
}