import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from '../../../shared/config/http';
import { TaskModel } from '../models/task';
import { CategoryModel } from '../models/category';
export interface ILocationModel {
    fbValue: string,
    clValue: string,
    ouValue: string,
    name: string,
    state: string,
}

export interface TasksInitialState {
    isLoading: boolean;
    tasks: TaskModel[];
    keywords: [];
    taskLocations: [];
    total: number;
    locations: ILocationModel[] | undefined;
    errorMessage: string;
    category: CategoryModel[];
}

export const getUserTasks: any = createAsyncThunk('getUserTasks', async (queryData: any) => {
    console.log(queryData);
    let responseData: any = {
        errorMessage: '',
    };

    let query = null;
    if (queryData.all) {
        query = axios
            .get(`usertasks`);
    }
    else {
        const queryParams: any = {
            limit: queryData.limit || 10,
            skip: queryData.skip || 0,
        };

        if (queryData.user) {
            queryParams.user = queryData.user;
        }

        if (queryData.sortBy) {
            const sortDirection = queryData.sortDirection || 'desc';

            queryParams.sortBy = `${queryData.sortBy}:${sortDirection}`;
        }

        if (queryData.keyword) {
            queryParams.keyword = queryData.keyword;
        }

        if (queryData.location) {
            queryParams.location = JSON.stringify(queryData.location);
        }

        if (queryData.marketplace) {
            queryParams.marketplace = queryData.marketplace;
        }

        query = axios
            .get(`usertasks`, { params: queryParams });
    }

    await query
        .then((response) => {
            responseData = response;
        });

    return responseData;
});

export const getUserTaskById: any = createAsyncThunk('getUserTaskById', async (id: any) => {
    let responseData: any = {
        errorMessage: '',
    };

    await axios
        .get(`usertasks/${id}`)
        .then((response) => {
            responseData = response;
        });

    return responseData;
});

export const createUserTask: any = createAsyncThunk('createUserTask', async (data: any) => {
    let responseData: any = {
        errorMessage: '',
    };

    await axios
        .post(`tasks`, data)
        .then((response) => {
            responseData = response;
            console.log(response)
        })
        .catch((err) => {
            responseData.code = err.response.status;
            //responseData.errorMessage = 'Something went wrong'
        });

    return responseData;
});

export const updateUserTask: any = createAsyncThunk('updateUserTask', async (data: any) => {
    let responseData: any = {
        errorMessage: '',
    };

    delete data.createdAt;
    delete data.updatedAt;
    delete data.__v;
    await axios
        .patch(`usertasks/${data._id}`, data)
        .then((response) => {
            responseData = response;
        }).catch(() => console.log('received invalid response, continuing...'));

    return responseData;
});

export const deleteUserTask: any = createAsyncThunk('deleteUserTask', async (id: any) => {
    let responseData: any = {
        errorMessage: '',
    };

    await axios
        .delete(`usertasks/${id}`)
        .then((response) => {
            responseData = response;
        });

    return responseData;
});

export const getListLocation: any = createAsyncThunk('getListLocation', async () => {
    let responseData: any = {
        errorMessage: '',
    };

    await axios
        .get(`/location`)
        .then((response) => {
            responseData = response;
        })
    return responseData;
})

export const getCategory: any = createAsyncThunk('getCategory', async () => {
    let responseData: any = {
        errorMessage: '',
    };

    await axios
        .get(`/category`)
        .then(response => responseData = response);
    return responseData;
});

const initialState: TasksInitialState = {
    isLoading: false,
    tasks: [],
    keywords: [],
    taskLocations: [],
    total: 0,
    locations: undefined,
    errorMessage: '',
    category: [],
};

const reduceCities = (item: any) => {
    if (!Array.isArray(item)) {
        item = [item];
    }
    return item.reduce((accInner: any, itemInner: any) => {
        itemInner.cities.map((v: any) => accInner.push({
            name: v.name,
            fbValue: v.fbValue,
            clValue: v.clvalue,
            ouValue: v.offerupValue,
            state: itemInner.state
        }));
        return accInner;
    }, []);
}

export const setError = createAction<string>('setError');

const tasksReducer = createSlice({
    name: 'tasks',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserTasks.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getUserTasks.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tasks = action.payload.data.data;
            state.total = action.payload.data.total;
            state.keywords = action.payload.data.keywords;
            state.taskLocations = action.payload.data.taskLocations;
        });
        builder.addCase(createUserTask.fulfilled, (state, action) => {
            state.errorMessage = action.payload.errorMessage
        })
        builder.addCase(setError, (state, action) => {
            state.errorMessage = action.payload
        })
        builder.addCase(getListLocation.fulfilled, (state, action) => {
            state.locations = action.payload.data.reduce((acc: any, item: any) => {
                const res = reduceCities(item);
                acc.push(...res);
                return acc;
            }, [])
        })
        builder.addCase(getCategory.fulfilled, (state, action) => {
            state.category = action.payload.data;
        });
    },
});

export default tasksReducer.reducer;
