import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from '../../../shared/config/http';
import { LogModel } from '../models/log';

export interface LogsInitialState {
    isLoading: boolean;
    logs: LogModel[];
    total: number;    
    errorMessage: string;    
}

export const getLogs: any = createAsyncThunk('getLogs', async (queryData: any) => {
    let responseData: any = {
        errorMessage: '',
    };

    let query = null;
    if (queryData.all) {
        query = axios
            .get(`logs`);
    }
    else {
        const queryParams: any = {
            limit: queryData.limit || 10,
            skip: queryData.skip || 0,
        };

        if (queryData.sortBy) {
            const sortDirection = queryData.sortDirection || 'desc';

            queryParams.sortBy = `${queryData.sortBy}:${sortDirection}`;
        }

        query = axios
            .get(`logs`, { params: queryParams });
    }

    await query
        .then((response) => {
            responseData = response;
        });

    return responseData;
});


export const getListLocation: any = createAsyncThunk('getListLocation', async () => {
    let responseData: any = {
        errorMessage: '',
    };

    await axios
        .get(`/location`)
        .then((response) => {
            responseData = response;
        })
    return responseData;
})

export const getCategory: any = createAsyncThunk('getCategory', async () => {
    let responseData: any = {
        errorMessage: '',
    };

    await axios
        .get(`/category`)
        .then(response => responseData = response);
    return responseData;
});

const initialState: LogsInitialState = {
    isLoading: false,
    logs: [],
    total: 0,    
    errorMessage: '',    
};


export const setError = createAction<string>('setError');

const logsReducer = createSlice({
    name: 'logs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLogs.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getLogs.fulfilled, (state, action) => {
            state.isLoading = false;
            state.logs = action.payload.data.data;
            state.total = action.payload.data.total;
        });        
        builder.addCase(setError, (state, action) => {
            state.errorMessage = action.payload
        })
    },
});

export default logsReducer.reducer;
