import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from '../../../shared/config/http';
import axiosMain from 'axios';
import { AdModel } from '../models/ads';
import { UserModel } from "../../Users/models/user";
import { TaskModel } from '../../Tasks/models/task';

export interface AdsInitialState {
    ads: AdModel[];
    total: number;
    isLoading: boolean;
    keywordSearch: string;
    marketplaceSearch: string;
    locationSearch: any;
    userSearch: string;
    users: UserModel[];
    currentUser: UserModel | undefined;
    currentTask: TaskModel | undefined;
}

export const getAds: any = createAsyncThunk('getAds', async (queryData: any, thunkApi: any) => {
    let responseData: any = {
        errorMessage: '',
    };

    const source = axiosMain.CancelToken.source();
    thunkApi.signal.addEventListener('abort', () => {
        source.cancel();
    });

    let query: any = {
        limit: queryData.limit || 10,
        skip: queryData.skip || 0
    };

    if (queryData.keywords) {
        query.keywords = queryData.keywords;
    }

    if (queryData.marketplace) {
        query.marketplace = queryData.marketplace;
    }

    if (queryData.radius) {
        query.radius = queryData.radius;
    }

    if (queryData.isFavourite) {
        query.isFavourite = queryData.isFavourite;
    }

    if (queryData.sortBy) {
        const sortDirection = queryData.sortDirection || 'desc';
        query.sortBy = `${queryData.sortBy}:${sortDirection}`;
    }

    if (queryData.user) {
        query.user = queryData.user;
    }

    await axios
        .get('ads', { cancelToken: source.token, params: query })
        .then((response) => {
            responseData = response;
        });

    return responseData;
});

export const getAd: any = createAsyncThunk('getAd', async (queryData: any, thunkApi: any) => {
    let responseData: any = {
        errorMessage: '',
    };

    const source = axiosMain.CancelToken.source();
    thunkApi.signal.addEventListener('abort', () => {
        source.cancel();
    });

    let id = queryData.id

    await axios
        .get(`ads/${id}`, { cancelToken: source.token })
        .then((response) => {
            responseData = response;
        });

    return responseData;
});

export const setIsFav: any = createAsyncThunk('setIsFav', async (data: any) => {
    await axios
        .patch(`ad/favourite/${data.id}`, { isFavourite: data.isFavourite});
});

export const setKeywordSearch = createAction<string>('setKeywordSearch');

export const setMarketplaceSearch = createAction<string>('setMarketplaceSearch');

export const setLocationSearch = createAction<any>('setLocationSearch');

export const setUserSearch = createAction<string>('setUserSearch');

export const setCurrentTask = createAction<TaskModel | undefined>('setCurrentTask');

export const setCurrentUser = createAction<UserModel | undefined>('setCurrentUser');

const getSavedItem = (key: string) => {
    return sessionStorage.getItem(key);
}

const getTypedSavedItem = <T>(key: string) => {
    const item = getSavedItem(key);
    if (item) {
        return <T>JSON.parse(item);
    }

    return undefined;
}

const initialState: AdsInitialState = {
    ads: [],
    total: 0,
    isLoading: false,
    keywordSearch: getSavedItem('keywordSearch') || '',
    locationSearch: getSavedItem('locationSearch') || '',
    marketplaceSearch: getSavedItem('marketplaceSearch') || '',
    userSearch: getSavedItem('userSearch') || '',
    users: [],
    currentUser: getTypedSavedItem<UserModel | undefined>('currentUser'),
    currentTask: getTypedSavedItem<TaskModel | undefined>('currentTask')
};

const adsReducer = createSlice({
  name: 'ads',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
        builder.addCase(getAds.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAds.fulfilled, (state, action) => {
            state.ads = action.payload.data.data;
            state.total = action.payload.data.total;
            state.isLoading = false;
        });
        builder.addCase(getAd.fulfilled, (state, action) => {
            state.ads = action.payload.data.data;
            state.total = action.payload.data.total;
            state.isLoading = false;
        });
        builder.addCase(setKeywordSearch, (state, action) => {
            state.keywordSearch = action.payload;
            if (action.payload) {
                sessionStorage.setItem('keywordSearch', action.payload);
            } else {
                sessionStorage.removeItem('keywordSearch');
            }
        });
        builder.addCase(setLocationSearch, (state, action) => {
            state.locationSearch = action.payload;
            if (action.payload) {
                sessionStorage.setItem('locationSearch', action.payload);
            } else {
                sessionStorage.removeItem('locationSearch');
            }
        });
        builder.addCase(setMarketplaceSearch, (state, action) => {
            state.marketplaceSearch = action.payload;
            if (action.payload) {
                sessionStorage.setItem('marketplaceSearch', action.payload);
            } else {
                sessionStorage.removeItem('marketplaceSearch');
            }
        });
        builder.addCase(setUserSearch, (state, action) => {
            state.userSearch = action.payload;
            if (action.payload) {
                sessionStorage.setItem('userSearch', action.payload);
            } else {
                sessionStorage.removeItem('userSearch');
            }
        });
        builder.addCase(setCurrentTask, (state, action) => {
            state.currentTask = action.payload;
            if (action.payload) {
                sessionStorage.setItem('currentTask', JSON.stringify(action.payload));
            } else {
                sessionStorage.removeItem('currentTask');
            }
        });
        builder.addCase(setCurrentUser, (state, action) => {
            state.currentUser = action.payload;
            if (action.payload) {
                sessionStorage.setItem('currentUser', JSON.stringify(action.payload));
            } else {
                sessionStorage.removeItem('currentUser');
            }
        });
    },
});

export default adsReducer.reducer;
