import axios from 'axios';
import { getToken } from '../../utils/localStorage';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  responseType: 'json',
});

instance.interceptors.request.use((request) => {
  if (request.method === 'post') {
    // @ts-ignore
    request.headers['Content-Type'] = 'application/json';
  }
  const token = getToken();
  if (token) {
    // @ts-ignore
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});

export default instance;
