import React from "react";
import async from "../components/Async";
import {
  Briefcase,
  CheckSquare,
  User,
  Users, 
  List  
} from "react-feather";
import { StarBorder } from "@material-ui/icons";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Pages components
const Blank = async(() => import("../pages/pages/Blank"));
const EditProfile = async(() => import("../pages/pages/EditProfile"));
const Tasks = async(() => import("../feature/Tasks/components/TaskPage"));
const AdsPage = async(() => import("../feature/Ads/components/AdsPage"));
const AdDetail = async(() => import("../feature/Ads/components/AdDetailPage"));
const FavoritesPage = async(() => import("../feature/Favorites/components/FavoritesPage"));
const UsersPage = async(() => import("../feature/Users/components/UsersPage"));
const LogsPage = async(() => import("../feature/Logs/components/LogsPage"));

const profileRoutes = {
  id: "Profile",
  path: "/profile",
  icon: <User />,
  component: EditProfile,
  children: null,
  isPrivate: true,
};

const adsRoutes = {
  id: "Listings",
  path: "/listings",
  icon: <Briefcase />,
  component: AdsPage,
  children: null,
  containsHome: true,
  isPrivate: true,
};

const usersRoutes = {
  id: "Users",
  path: "/users",
  icon: <Briefcase />,
  component: UsersPage,
  children: null,
  containsUsers: true,
  isPrivate: true,
};

const logsRoutes = {
  id: "Logs",
  path: "/logs",
  icon: <List />,
  component: LogsPage,
  children: null,
  containsUsers: true,
  isPrivate: true,
};

const favRoutes = {
  id: "Favorites",
  path: "/favorites",
  icon: <StarBorder />,
  component: FavoritesPage,
  children: null,
  containsHome: true,
  isPrivate: true,
};

const tasksRoutes = {
  id: "Search",
  path: "/",
  icon: <CheckSquare />,
  component: Tasks,
  children: null,
  isPrivate: true,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ],
  component: null,
  isPrivate: false,
};

// This route is not visisble in the sidebar
const privateRoutes = {
  id: "Private",
  path: "/private",
  component: Blank,
  children: null,
};

const adRoutes = {
  id: "Ad Detail",
  path: "/ads/:id",  
  component: AdDetail,
  children: null,
  isPrivate: false
};


export const dashboard = [
  tasksRoutes,
  adsRoutes,
  favRoutes,
  profileRoutes,
  adRoutes,
  usersRoutes,
  logsRoutes
];

export const auth = [authRoutes];

export default [
  profileRoutes,
  adsRoutes,
  tasksRoutes,  
  usersRoutes,
  authRoutes
];
