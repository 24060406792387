import React from "react";
import { BrowserRouter as Router, Route, RouteProps, Switch, RouteComponentProps, Redirect } from "react-router-dom";
import { dashboard as dashboardRoutes, auth as authRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import { RouteInfoType } from "../types/types";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const PrivateRoute = (settings: RouteProps) => {
  const { isAuth } = useSelector((state: RootState) => ({
    isAuth: state.authReducer.isAuth,
  }));
  const { component: Component, ...rest } = settings;

  const routeRender = (props: RouteComponentProps<any>) =>
    isAuth ? (
      typeof settings.render === 'function' && settings.render(props)
    ) : (
        <Redirect to={{ pathname: '/auth/sign-in', state: { referer: props.location } }} />
      );
  return <Route {...rest} render={routeRender} />;
};


const childRoutes = (Layout: React.ElementType, routes: Array<RouteInfoType>) => {
  return routes.map(({ component: Component, children, path, isPrivate }, index: number) => {
    return children ? (
      children.map((element, index: number) => (
        isPrivate ? (<PrivateRoute key={index}
          path={element.path}
          exact
          render={(props: RouteComponentProps) => (
            <Layout>
              <element.component {...props} />
            </Layout>
          )} />) :
          <Route
            key={index}
            path={element.path}
            exact
            render={(props: RouteComponentProps) => (
              <Layout>
                <element.component {...props} />
              </Layout>
            )}
          />
      ))
    ) : Component ?
        (
          isPrivate ?
            <PrivateRoute
              key={index}
              path={path}
              exact
              render={props => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            /> :
            <Route
              key={index}
              path={path}
              exact
              render={props => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
        ) : null
  });
}

const Routes = () => {
  return <Router>
    <Switch>
      {childRoutes(DashboardLayout, dashboardRoutes)}
      {childRoutes(AuthLayout, authRoutes)}
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
};

export default Routes;