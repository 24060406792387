import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from '../../../shared/config/http';
import { UserModel } from '../models/user';

export interface UsersInitialState {
    isLoading: boolean;
    users: UserModel[];
    total: number;    
    errorMessage: string;    
}

export const getUsers: any = createAsyncThunk('getUsers', async (queryData: any) => {
    let responseData: any = {
        errorMessage: '',
    };

    let query = null;
    if (queryData.all) {
        query = axios
            .get(`users`);
    }
    else {
        const queryParams: any = {
            limit: queryData.limit || 10,
            skip: queryData.skip || 0,
        };

        if (queryData.sortBy) {
            const sortDirection = queryData.sortDirection || 'desc';

            queryParams.sortBy = `${queryData.sortBy}:${sortDirection}`;
        }

        query = axios
            .get(`users`, { params: queryParams });
    }

    await query
        .then((response) => {
            responseData = response;
        });

    return responseData;
});

export const getUserTaskById: any = createAsyncThunk('getUserById', async (id: any) => {
    let responseData: any = {
        errorMessage: '',
    };

    await axios
        .get(`users/${id}`)
        .then((response) => {
            responseData = response;
        });

    return responseData;
});

export const createUserTask: any = createAsyncThunk('createUser', async (data: any) => {
    let responseData: any = {
        errorMessage: '',
    };

    await axios
        .post(`users`, data)
        .then((response) => {
            responseData = response;
            console.log(response)
        })
        .catch((err) => {
            responseData.code = err.response.status;
            //responseData.errorMessage = 'Something went wrong'
        });

    return responseData;
});

export const updateUserTask: any = createAsyncThunk('updateUser', async (data: any) => {
    let responseData: any = {
        errorMessage: '',
    };

    delete data.createdAt;
    delete data.updatedAt;
    delete data.__v;
    await axios
        .patch(`users/${data._id}`, data)
        .then((response) => {
            responseData = response;
        }).catch(() => console.log('received invalid response, continuing...'));

    return responseData;
});

export const deleteUser: any = createAsyncThunk('deleteUser', async (id: any) => {
    let responseData: any = {
        errorMessage: '',
    };

    await axios
        .delete(`users/${id}`)
        .then((response) => {
            responseData = response;
        });

    return responseData;
});

export const getListLocation: any = createAsyncThunk('getListLocation', async () => {
    let responseData: any = {
        errorMessage: '',
    };

    await axios
        .get(`/location`)
        .then((response) => {
            responseData = response;
        })
    return responseData;
})

export const getCategory: any = createAsyncThunk('getCategory', async () => {
    let responseData: any = {
        errorMessage: '',
    };

    await axios
        .get(`/category`)
        .then(response => responseData = response);
    return responseData;
});

const initialState: UsersInitialState = {
    isLoading: false,
    users: [],
    total: 0,    
    errorMessage: '',    
};


export const setError = createAction<string>('setError');

const usersReducer = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUsers.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.users = action.payload.data;
            state.total = action.payload.length;
        });
        builder.addCase(createUserTask.fulfilled, (state, action) => {
            state.errorMessage = action.payload.errorMessage
        })
        builder.addCase(setError, (state, action) => {
            state.errorMessage = action.payload
        })
    },
});

export default usersReducer.reducer;
