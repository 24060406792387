import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../shared/config/http';
import { logOutBy } from '../../Auth/store';

export interface ProfileInitialState {
    email: string;
    name: string;
    userType: string;
    notificationInterval: number;
}

export const getUserInformation: any = createAsyncThunk('getUserInformation', async () => {
    let responseData: any = {
      errorMessage: '',
    };
  
    await axios
      .get('users/me')
      .then((response) => {
        responseData = response;
      });
  
    return responseData;
  });

export const updateUserInformation: any = createAsyncThunk('updateUserInformation', async (data) => {
    let responseData = {};

    await axios
        .patch('users/me', data)
        .then((response) => {
            responseData = response;
        });

    return responseData;
});

export const deleteUserInformation: any = createAsyncThunk('deleteUserInformation', async (data) => {
    let responseData = {};
    await axios
    .delete('users/me')
    .then((response) => {
        responseData = response;
    });
});

const initialState: ProfileInitialState = {
    email: '',
    name: '',
    userType: 'user',
    notificationInterval: 0
};

const profileReducer = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
        builder.addCase(getUserInformation.fulfilled, (state, action) => {
            state.email = action.payload.data.email;
            state.name = action.payload.data.name;
            state.userType = action.payload.data.userType;
            state.notificationInterval = action.payload.data.notificationInerval;
        });
        builder.addCase(updateUserInformation.fulfilled, (state, action) => {
            state.email = action.payload.data.email;
            state.name = action.payload.data.name;
            state.userType = action.payload.data.userType;
            state.notificationInterval = action.payload.data.notificationInerval;
        });
        builder.addCase(deleteUserInformation.fulfilled, (state, action) => {
            state.email = '';
            state.name = '';
            state.userType = "user";
            state.notificationInterval = 0;
        });
        builder.addCase(logOutBy, (state) => {
            state.email = '';
            state.name = '';
            state.userType = "user";
            state.notificationInterval = 0;
        });
    },
});

export default profileReducer.reducer;
