import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../shared/config/http';
import { deleteToken, setToken, getToken } from '../../../utils/localStorage';

export interface AuthInitialState {
  isAuth: boolean;
  loading: boolean;
  errorMessage: string;
}

export const logOutBy: any = createAction('logOutBy');
export const resetErrorMessageBy: any = createAction('resetErrorMessageBy');

export const registrationBy: any = createAsyncThunk('registrationBy', async (data: any) => {
  let responseData: any = {
    errorMessage: '',
  };

  await axios
    .post('users', data)
    .then((response) => {
      responseData = response;
      responseData.isAuth = true;
    })
    .catch(err => {
      console.error(err.toJSON());
      responseData.errorMessage = 'Something went wrong';
    })

  return responseData;
});

export const loginBy: any = createAsyncThunk('loginBy', async (data: any) => {
  let responseData: any = {
    errorMessage: '',
  };

  await axios
    .post('users/login', data)
    .then((response) => {
      responseData = response;
      responseData.errorMessage = '';
      responseData.isAuth = true;
    })
    .catch(err => {
      console.error(err.toJSON())
      responseData.errorMessage = 'Something went wrong';
    })

  return responseData;
});

const initialState: AuthInitialState = {
  loading: false,
  errorMessage: '',
  isAuth: !!getToken(),
};

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registrationBy.pending, (state) => {
        state.errorMessage = '';
    });

    builder.addCase(registrationBy.fulfilled, (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload.errorMessage;
    });
    builder.addCase(registrationBy.rejected, (state, action) => {
      state.loading = false;
      state.errorMessage = "Something went wrong";
    });
    builder.addCase(loginBy.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginBy.fulfilled, (state, action) => {
      state.loading = false;
      state.errorMessage = action.payload.errorMessage;
      if (action.payload.data) {
        setToken(action.payload.data.token);
        state.isAuth = true;
      }
    });
    builder.addCase(loginBy.rejected, (state, action) => {
      state.loading = false;
      state.errorMessage = "Something went wrong";
    });
    builder.addCase(logOutBy, (state) => {
      deleteToken();
      state.errorMessage = '';
      state.isAuth = false;
    });
    builder.addCase(resetErrorMessageBy, (state) => {
      state.loading = false;
      state.errorMessage = '';
    });
  },
});

export default authReducer.reducer;
