export const getToken = (): string | null => {
    return window.localStorage.getItem('access_token');
};

export const setToken = (idToken: string): void => {
    return window.localStorage.setItem('access_token', idToken);
};

export const deleteToken = (): void => {
    return window.localStorage.removeItem('access_token');
};