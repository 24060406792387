import React from "react";
import { connect, useSelector } from "react-redux";

import { Helmet } from 'react-helmet';

import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";

import maTheme from "./theme";
import Routes from "./routes/Routes";
import { RootState } from "./store";

function App() {
  const { currentTheme } = useSelector((state: RootState) => ({
    currentTheme: state.themeReducer.currentTheme
  }))

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | C2BPro"
        defaultTitle="Dashboard"
      />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[currentTheme]}>
            <ThemeProvider theme={maTheme[currentTheme]}>
              <Routes />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
