import React, { useEffect } from 'react';
import styled from "styled-components";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { getUserInformation } from '../feature/Profile/store';
import { Grid, Typography } from '@material-ui/core';

const SidebarFooter = styled.div`
  background-color: #555 !important;
  padding: ${props => props.theme.spacing(2.75)}px
    ${props => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: #fff;
`;

const SidebarFooterSubText = styled(Typography)`
  color: #fff;
  font-size: .725rem;
  display: block;
  padding: 1px;
`;

const AppSidebarFooter = () => {
    const { email, name } = useSelector((state: RootState) => ({
        email: state.profileReducer.email,
        name: state.profileReducer.name,
    }));
    const dispatch = useDispatch();

    useEffect(() => {
      if (!email && !name) {
        dispatch(getUserInformation());
      }
    }, []);

    return <SidebarFooter>
        <Grid container spacing={2}>
            <Grid item>
                <SidebarFooterText variant="body2">
                    {name}
                </SidebarFooterText>
                <SidebarFooterSubText variant="caption">
                    {email}
                </SidebarFooterSubText>
            </Grid>
        </Grid>
    </SidebarFooter>
};

export default AppSidebarFooter;
