import { createAction, createSlice } from '@reduxjs/toolkit';

export interface ThemeInitialState {
    currentTheme: number;
}

export const setTheme = createAction<number>('setTheme');

const initialState: ThemeInitialState = {
    currentTheme: 4
};

const themeReducer = createSlice({
  name: 'theme',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setTheme, (state, action) => {
      state.currentTheme = action.payload;
    });
  },
});

export default themeReducer.reducer;
